// Popup.jsx
import React, { useEffect, useState } from 'react';

function Popup() {
  const [showPopup, setShowPopup] = useState(false);
  const [permanentlyClosed, setPermanentlyClosed] = useState(() => {
    return localStorage.getItem('permanentlyClosed') === 'true';
  });
  const [closeCount, setCloseCount] = useState(0);

  useEffect(() => {
    if (permanentlyClosed) return;

    const intervalId = setInterval(() => {
      const cookiesAccepted = document.cookie
        .split('; ')
        .find(row => row.startsWith('cookiesAccepted='));

      if (cookiesAccepted) {
        const cookieValue = cookiesAccepted.split('=')[1];

        setTimeout(() => {
          setShowPopup(true);
        }, 10000);

        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [permanentlyClosed]);

  const closePopup = () => {
    setShowPopup(false);
    setCloseCount(prevCount => prevCount + 1);

    if (closeCount + 1 >= 2) {
      setPermanentlyClosed(true);
      localStorage.setItem('permanentlyClosed', 'true');
    } else {
      setTimeout(() => {
        setShowPopup(true);
      }, 10000);
    }
  };

  const handlePermanentClose = () => {
    setShowPopup(false);
    setPermanentlyClosed(true);
    localStorage.setItem('permanentlyClosed', 'true');
  };

  if (!showPopup || permanentlyClosed) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={closePopup}>X</button>
        <p className="popup-text">
          To read the new article on the Digital Literacy Initiative for vulnerable women, <a href="/digital-literacy-initiative-for-vulnerable-women-in-armenia" onClick={handlePermanentClose}>click here</a>.
        </p>
      </div>
    </div>
  );
}

export default Popup;
