// CookieBanner.jsx
import React, { useState } from 'react';

const CookieBanner = () => {
    const [accepted, setAccepted] = useState(() => {
        const cookiesAccepted = document.cookie
            .split('; ')
            .find(row => row.startsWith('cookiesAccepted='));
        return cookiesAccepted ? cookiesAccepted.split('=')[1] === 'true' : null;
    });

    const acceptCookies = () => {
        document.cookie = 'cookiesAccepted=true; path=/; max-age=' + 60 * 60 * 24 * 365;
        setAccepted(true); 
    };

    const denyCookies = () => {
        document.cookie = 'cookiesAccepted=false; path=/; max-age=' + 60 * 60 * 24 * 365; 
        setAccepted(false); 
    };

    if (accepted !== null) {
        return null; 
    }

    return (
        <div id="cookies-banner">
            <p>
                We use cookies and other identifiers to help improve your online experience.
                By using our website you are agreeing to this. Read our privacy policy to
                find out what cookies are used for and how to change your settings.
            </p>
            <button id="accept-cookies" onClick={acceptCookies}>Accept</button>
            <button id="deny-cookies" onClick={denyCookies}>Deny</button>
        </div>
    );
};

export default CookieBanner;
